@import 'variables';
@import 'typography';

.pink-anchor {
  color: color('brandColor500');
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
