@mixin headline-h2($weight: 600, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(20px);
  line-height: 1.2;

  @include mq('laptop') {
    font-size: rem(24px);
  }
}

@mixin headline-h3($weight: 500, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(14px);
  line-height: 1.2;

  @include mq('laptop') {
    font-size: rem(18px);
  }
}

@mixin headline-h3-lg($weight: 500, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(16px);
  line-height: 1.2;

  @include mq('laptop') {
    font-size: rem(21px);
  }

  @include mq('desktop') {
    font-size: rem(24px);
  }
}

@mixin headline-h4($weight: 400, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(16px);
  line-height: 1.2;

  @include mq('laptop') {
    font-size: rem(18px);
  }
}

@mixin paragraph($weight: 400, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(13px);
  line-height: 1.2;

  @include mq('tablet') {
    font-size: rem(14px);
  }
}

@mixin paragraph-14($weight: 400, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(14px);
  line-height: rem(19px);
}

@mixin coupon($weight: 700, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(16px);
  line-height: 1.2;

  @include mq('laptop') {
    font-size: rem(18px);
  }
}

@mixin rules($weight: 400, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(12px);
  line-height: rem(16px);
  text-align: justify;

  @include mq('tablet') {
    font-size: rem(14px);
    line-height: rem(20px);
  }
}

@mixin subtitle-newsletter($weight: 400, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(13px);
  line-height: 1.2;

  @include mq('tablet') {
    font-size: rem(18px);
  }
}

@mixin small($weight: 400, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(12px);
  line-height: 1.2;

  @include mq('tablet') {
    font-size: rem(12px);
  }
}

//componente de assinaturas
@mixin cardBodyFont($weight: 400, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(13px);
  line-height: rem(17px);
  letter-spacing: rem(0.033px);
  color: color('gray700');

  @include mq('laptop') {
    line-height: rem(18px);
    font-size: rem(14px);
    letter-spacing: rem(0.07px);
  }
}

@mixin pricesClub($weight: 600, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(16px);
  line-height: rem(18px);
  letter-spacing: rem(-0.16px);

  @include mq('laptop') {
    font-size: rem(18px);
    line-height: rem(22px);
    letter-spacing: rem(-0.18px);
  }
}

@mixin smallParagraphClub($weight: 400, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(13px);
  line-height: 1.2;

  @include mq('tablet') {
    font-size: rem(14px);
  }
}

@mixin oldPriceFontBold($weight: 600, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(13px);
  line-height: rem(17px);
  letter-spacing: rem(0.065px);
  text-decoration: line-through;
  color: color('gray450');

  @include mq('laptop') {
    font-size: rem(14px);
    line-height: rem(17px);
    letter-spacing: rem(0.07px);
  }
}

@mixin oldPriceFont($weight: 400, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(10px);
  line-height: rem(16px);
  letter-spacing: rem(0.04px);
  color: color('gray450');

  @include mq('laptop') {
    font-size: rem(12px);
    line-height: rem(16px);
    letter-spacing: rem(0.048px);
  }
}

@mixin planLabel($weight: 600, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(13px);
  line-height: rem(17px);
  letter-spacing: rem(0.065px);
  color: color('gray700');

  @include mq('laptop') {
    font-size: rem(14px);
    line-height: rem(18px);
    letter-spacing: rem(0.07px);
  }
}

@mixin cardBestSellerFont($weight: 700, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(10px);
  line-height: rem(16px);
  letter-spacing: rem(0.04px);
  color: white;
}

@mixin cardSubtitle($weight: 600, $font: $base-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(14px);
  line-height: rem(18px);
  letter-spacing: rem(0.014px);
  color: color('gray450');

  @include mq('laptop') {
    font-size: rem(16px);
    line-height: rem(20px);
    letter-spacing: rem(0.024px);
  }
}

@mixin cardTitle($weight: 600, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(16px);
  line-height: rem(18px);
  letter-spacing: rem(0.016px);
  color: color('gray700');

  @include mq('laptop') {
    font-size: rem(18px);
    line-height: rem(22px);
    letter-spacing: rem(0.027px);
  }
}

@mixin headline-club($weight: 600, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-style: normal;
  font-size: rem(20px);
  line-height: 1.2;

  @include mq('laptop') {
    font-size: rem(24px);
  }
}

@mixin button($weight: 400, $font: $second-font) {
  font-family: $font;
  font-weight: $weight;
  font-size: rem(16px);
  line-height: 1.2;

  @include mq('laptop') {
    font-size: rem(18px);
  }
}
