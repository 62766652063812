@import 'variables';
@import 'typography';

.banner-queen-cta {
  color: color('white');
  background-color: color('orange');
  padding: rem(15px);
  font-size: rem(18px);
  border-radius: rem(5px);

  @include mq('laptop') {
    padding: rem(20px);
    font-size: rem(24px);
  }
}

.queen-cta {
  color: color('white');
  background-color: color('brandColor500');
  padding: rem(15px);
  font-size: rem(18px);
  border-radius: rem(5px);

  @include mq('laptop') {
    padding: rem(20px);
    font-size: rem(24px);
  }

  &--purple {
    background-color: color('purple');
  }

  &--soft-pink {
    background-color: color('brandColor400');
  }
}

.signature-button {
  border: rem(1.7px) solid color('brandColor500');
  border-radius: rem(6px);
  padding: rem(15px);
  font-size: rem(12px);
  color: color('gray700');

  @include mq('lg-mobile') {
    font-size: rem(16px);
  }

  &--purple {
    border: rem(1.7px) solid color('purple');
  }

  &--soft-pink {
    border: rem(1.7px) solid color('brandColor400');
  }

  &--active {
    background: color('brandColor500');
    font-weight: 600;
    color: color('white');

    @include mq('tablet') {
      font-weight: 700;
    }
  }

  &--active--purple {
    background: color('purple');
  }

  &--active--soft-pink {
    background: color('brandColor400');
  }
}

.partnership-button {
  width: 100%;
  height: rem(40px);
  color: color('white');
  background-color: color('brandColor500');
  padding: rem(15px);
  font-size: rem(18px);
  border-radius: rem(5px);
  margin: rem(30px) auto 0;
  font-family: $second-font;
  font-weight: 600;

  @include mq('laptop') {
    max-width: rem(380px);
    height: rem(50px);
    padding: rem(20px);
    font-size: rem(24px);
  }
}

.newsletter-button {
  width: 100%;
  height: rem(40px);
  color: color('white');
  background-color: color('brandColor500');
  padding: rem(15px);
  font-size: rem(18px);
  border-radius: rem(5px);

  @include mq('laptop') {
    max-width: rem(181px);
    height: rem(50px);
    padding: rem(20px);
    font-size: rem(24px);
  }

  &--disabled {
    opacity: 0.5;
    background-color: color('gray100');
    cursor: default;
  }

  &--enable {
    opacity: 0.5;
    background-color: color('gray100');
    cursor: default;
  }
}

.plans-card {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: rem(12px) rem(20px);
  border-radius: rem(5px);
  width: 100%;
  background-color: color('brandColor500');

  .button-text {
    color: #fff;
    @include button(600);
  }

  &--purple {
    background-color: color('purple');
  }

  &--soft-pink {
    background-color: #fe86b0;
  }

  &--orange {
    background-color: color('orange100');
  }

  &--outline-pink {
    border: rem(1px) solid color('brandColor500');
    background-color: #fff;

    .button-text {
      color: color('brandColor500');
      @include button(600);
    }
  }

  &--outline-purple {
    border: rem(1px) solid color('purple');
    background-color: #fff;

    .button-text {
      color: color('purple');
      @include button(600);
    }
  }

  &--outline-soft-pink {
    border: rem(1px) solid color('brandColor400');
    background-color: #fff;

    .button-text {
      color: color('brandColor400');
      @include button(600);
    }
  }

  &--outline-orange {
    border: rem(1px) solid color('orange100');
    background-color: #fff;

    .button-text {
      color: color('orange100');
      @include button(600);
    }
  }

}
