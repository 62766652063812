$grid-breakpoints: (
  xs: 0,
  sm: 480px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1440px
);

.container {
  padding: 0 rem(16px);
  max-width: calc(123.4rem + 1.6rem);
  width: 100%;
}
