@use 'sass:math';

$mq: (
  'super-sm-mobile': 281px,
  'sm-mobile': 320px,
  'lg-mobile': 480px,
  'tablet': 768px,
  'laptop': 1024px,
  'desktop': 1366px,
  'infinity': 1440px
);

$colors: (
  'white': #fff,
  'black': #000,
  'red0': #f6b7b7,
  'red10': #fff6fb,
  'red': #ba2424,
  'danger': #dc3545,

  'orange': #f75703,
  'orange50': #fff6ed,
  'orange100': #ff7100,
  'orange200': #fedfc7,
  'orange300': #ffa965,

  'pink50': #ffeff7,

  'purple': #8540f5,
  'purple50': #f8f1ff,
  'purple200': #bb95f8,
  'purple300': #f0e5fd,
  'purple500': #422779,

  'brandColor300': #ffdfee,
  'brandColor400': #fe86b0,
  'brandColor500': #fe357b,

  'gray00': #f7f7f7,
  'gray30': #e1e1e1,
  'gray40': #ededed,
  'gray100': #c4c4c4,
  'gray450': #6c757d,
  'gray500': #c4c4c4,
  'gray700': #343434,

  'green700': #008733,
  'green800': #008231
);

$shadows: (
  'card': 0 0 5px rgba(0, 0, 0, 20%),
  'glamclub': 0 4px 4px rgba(0, 0, 0, 0.25),
  'checkout': 4px 4px 25px rgba(0, 0, 0, 0.1)
);

// Functions
// ----------------------------------------------------------

@mixin mq($mq-value) {
  @media (min-width: map-get($mq, $mq-value)) {
    @content;
  }
}

@function shadow($shadow-value) {
  @return map-get($shadows, $shadow-value);
}

@function color($color-value) {
  @return map-get($colors, $color-value);
}

@function rem($size) {
  @return math.div($size, 10px) * 1rem;
}

// Default variables
// ----------------------------------------------------------

$base-font-size: 62.5%;
$base-font: 'Open Sans', sans-serif;
$second-font: 'Montserrat', sans-serif;
