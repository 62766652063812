@import './styles/index.scss';
@import './app/commons/buttons.scss';
@import './app/commons/anchor.scss';
@import './app/commons/radio.scss';
@import './app/commons/input.scss';
@import 'variables';
@import 'typography';

* {
  -webkit-tap-highlight-color: transparent;
}

h2 {
  @include headline-h2;
}

h3 {
  @include headline-h3;
}

.partnership-how-works__items-box__item__text__link {
  color: color('brandColor500');
  font-weight: 700;
  text-decoration: underline;
}

p {
  @include paragraph;
}

small {
  @include small();

  &.error {
    color: color(danger);
  }
}
