@import 'variables';

//Gerado com https://bun.js.org/

.a-contain *,
.a-contain *::before,
.a-contain *::after {
  box-sizing: content-box !important;
}

.a-contain input {
  display: none;
}

.a-contain {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  height: rem(24px);
  width: rem(24px);
  padding-left: rem(44px);
}

.a-contain input[type='radio'] ~ .a-input {
  position: absolute;
  top: 0;
  left: 0;
  height: rem(24px);
  width: rem(24px);
  background: color('white');
  transition: background 250ms;
  border: rem(1px) solid color('gray450');
  border-radius: rem(30px);
}

.a-contain input[type='radio'] ~ .a-input::after {
  content: '';
  position: absolute;
  display: none;
  left: rem(4px);
  top: rem(4px);
  width: rem(16px);
  height: rem(16px);
  border-radius: rem(30px);
  background: color('brandColor500');
  transition: background 250ms;
}

.a-contain input:checked ~ .a-input::after {
  display: block;
}

.a-contain:hover input[type='radio']:not([disabled]) ~ .a-input,
.a-contain input[type='radio']:focus ~ .a-input {
  background: color('white');
  border-color: color('brandColor500');
}

.a-contain input:focus ~ .a-input {
  box-shadow: 0 0 0 rem(1px) color('brandColor500');
}

.a-contain input[type='radio']:checked ~ .a-input {
  background: color('white');
  border-color: color('brandColor500');
}

.a-contain input[type='radio']:disabled ~ .a-input {
  opacity: 0.5;
  cursor: not-allowed;
}

.a-contain input[type='radio']:disabled ~ .a-input::after {
  background: color('white');
}

.a-contain:hover input[type='radio']:not([disabled]):checked ~ .a-input,
.a-contain input[type='radio']:checked:focus ~ .a-input {
  background: color('white');
  border-color: color('brandColor500');
}

//cards de planos
.radio {
  width: rem(24px);
  height: rem(24px);
  border: rem(1px) solid #656565;
  border-radius: rem(24px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;

  &--active {
    border: 1px solid color('brandColor500');
  }

  &--active-purple {
    border: 1px solid color('purple');
  }

  &--active-soft-pink {
    border: 1px solid color('brandColor400');
  }

  &--active-orange {
    border: 1px solid color('orange100');
  }

  &__button {
    width: rem(16px);
    height: rem(16px);
    background: color('brandColor500');
    border-radius: rem(16px);

    &--mobile {
      width: rem(14px);
      height: rem(14px);

      @include mq('tablet') {
        width: rem(16px);
        height: rem(16px);
      }
    }
  }

  &__button-purple {
    width: rem(16px);
    height: rem(16px);
    background: color('purple');
    border-radius: rem(16px);
  }

  &__button-soft-pink {
    width: rem(16px);
    height: rem(16px);
    background: color('brandColor400');
    border-radius: rem(16px);
  }

  &__button-orange {
    width: rem(16px);
    height: rem(16px);
    background: color('orange100');
    border-radius: rem(16px);
  }

  &--disabled {
    background-color: color('gray100');
    opacity: 0.5;
  }
}
