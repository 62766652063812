@import 'variables';
@import 'typography';


.input {
  //@include input();
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 1.6rem;
  line-height: 1.2;
  border: 0.05rem solid #c4c4c4;
  color: #343434;
  padding: 1rem;
  transition: 0.3s ease;
  width: 100%;
  max-height: 36px;
  max-height: unset !important;
  border-radius: 0.5rem;

  &-- &::placeholder {
    color: color('gray700');
    transition: 0.3s ease;
  }

  &:focus {
    border-color: color('brandColor500');
    box-shadow: 0 0 6px color('brandColor300');
    &::placeholder {
      color: color('gray200');
    }
  }

  small {
    @include small();
  
    &.error {
      color: color(danger);
    }
  }

  &--large {
    padding: rem(12px) rem(15px);
  }

  &--fullW {
    width: 100%;
  }

  &--fullH {
    height: 100%;
  }

  &--circle-border {
    border-radius: rem(5px);
  }

  &--invalid {
    border-color: color('danger');
  }

  &--validColor {
    border-color: color('green700');
    box-shadow: 0 0 6px #d9ede0;
  }

  &--login {
    color: color('gray450') !important;
    //@include smallParagraphClub();

    &::placeholder {
      color: color('gray100') !important;
    }
  }

  &--register {
    color: color('gray700') !important;
    //@include smallParagraphClub();

    &::placeholder {
      color: color('gray100') !important;
    }
  }
}
